//import React, { useContext, useState } from "react"
import React from "react"
import styles from "./contact-form.module.scss"
//import FeedbackFormComponent from "../../../forms/feedback"
//import Translate from "../../../../i18n/Translate"
//import feedbackService from "../../../../services/feedback-service"
//import { LangContext } from "../../../lang-context"
import ObnovleniyaBannerEnd from "../../../../components/pages/obnovleniya/obnovleniya-banner-end"

const ContactFormComponent = () => {
  return (
    <ObnovleniyaBannerEnd
    title='Попробуйте Параплан CRM прямо сейчас'
    subtitle='Бесплатный доступ к полной версии на 10 дней!' />
  )
  /*const lang = useContext(LangContext);
  const [success, setSuccess] = useState(false)
  const [registrationTokenSentToCustomer, setRegistrationTokenSentToCustomer] = useState(false)
  return (
    <div id="contact-form" className={`container ${styles.contact_form}`}>
      <div className={styles.bg}>
        <img alt=''
             src={require("../../../../images/contact_form_bg.png")}
             className={styles.bg}/>
      </div>
      <div className={styles.container}>
        <h2 className={styles.title}>
          <Translate id={success ? "form.thankYouHeader" : "form.formHeader"}/>
        </h2>
        <div className={styles.description}>
          <Translate id={
            success
              ? (registrationTokenSentToCustomer ? "form.thankYouMessageWithEmail" : "form.thankYouMessage")
              : "form.formMessage"
          }/>
        </div>
        <FeedbackFormComponent showMessage={true}
                               lang={lang}
                               onSuccess={(registrationTokenSentToCustomer) => {
                                 setSuccess(true)
                                 setRegistrationTokenSentToCustomer(registrationTokenSentToCustomer)
                                 feedbackService.fbPixelLeadEvent()
                               }}/>
      </div>
    </div>
  )*/
}

export default ContactFormComponent
